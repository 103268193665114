import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Accordion } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import AccordionHeader from '../components/utilities/accordion-header'
import MainCTA from '../components/sections/main-cta'
import QuestionForm from '../components/common/question-form'

const FAQPage = () => {
  const wpFAQ = useStaticQuery(graphql`
    query {
      allWpFaqCategory(sort: { fields: termTaxonomyId, order: ASC }, filter: { slug: { ne: "subscriptions" } }) {
        nodes {
          name
          fAQs {
            nodes {
              title
              content
              menuOrder
            }
          }
        }
      }
    }
  `)

  const newFAQItems = wpFAQ.allWpFaqCategory.nodes.map((node, index) => (
    <Accordion.Item key={`parent-${index}`} eventKey={`parent-${index}`}>
      <AccordionHeader eventKey={`parent-${index}`} wrapper="h2">
        <h2 className="h3">{node.name}</h2>
      </AccordionHeader>
      <Accordion.Collapse eventKey={`parent-${index}`}>
        <Accordion className="px-3 mb-1 accordion-child">
          {node.fAQs.nodes.map((node, index) => (
            <Accordion.Item key={`child-${index}`} eventKey={`child-${index}`}>
              <AccordionHeader eventKey={`child-${index}`} wrapper="h2">
                <h2 className="h3">{node.title}</h2>
              </AccordionHeader>
              <Accordion.Collapse eventKey={`child-${index}`}>
                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: node.content }} />
              </Accordion.Collapse>
            </Accordion.Item>
          ))}
        </Accordion>
      </Accordion.Collapse>
    </Accordion.Item>
  ))

  const mainEntry = wpFAQ.allWpFaqCategory.nodes.flatMap(node =>
    node.fAQs.nodes.map((node, index) => ({
      '@type': 'Question',
      name: node.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: node.content,
      },
    }))
  )

  const productobj = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: mainEntry,
  }

  return (
    <Layout className="faq">
      <Seo title="FAQs" productobj={productobj} />
      <header id="hero" className="wavy p-0 m-0 stack-parent">
        <StaticImage
          as="div"
          loading="eager"
          src="../images/hero/faq-hero-v2.jpg"
          quality={95}
          formats={['AUTO', 'WEBP']}
          alt="Meati Steak sandwich"
          placeholder="blurred"
          layout="fullWidth"
          // objectFit={'cover'}
          className="stack-child w-100"
        />
        <Container className="stack-child d-flex justify-content-center align-items-center">
          <h1 className="text-primary fst-italic m-0">FAQs</h1>
        </Container>
        <div className="header-static-overlay" />
      </header>
      <section className="py-2 py-md-5">
        <Container>
          <Accordion id="faqAccordion" className="accordion-flush">
            {newFAQItems}
          </Accordion>
        </Container>
      </section>
      <QuestionForm />
      <MainCTA />
    </Layout>
  )
}

export default FAQPage
